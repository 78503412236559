import React, { useState, useEffect, useReducer, useCallback } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import PlainPage from "../../templates/plain-page";
import {
  DonationsTable,
  NumberRangeColumnFilter,
  SelectColumnFilter,
  createReducer,
} from "../../components/DonationsTable";
import { toggleColumnExport } from "../../components/UsersTable";
import { db, storageRef } from "../../components/firebase/firestore";
import { formatDate } from "../../utils/utils";
import Avatar from "../../components/user/Avatar";

const calcFields = (row) => {
  const data = row.data();
  return {
    status: ["approved", "rejected"].includes(data.status)
      ? data.status
      : "awaiting",
    date: data.time ? new Date(data.time.seconds * 1000) : new Date(0),
    dateFormatted: data.time
      ? formatDate(new Date(data.time.seconds * 1000))
      : "",
    publish: data.publish ? "Yes" : "No",
    rowId: row.id,
  };
};

const getData = () =>
  Promise.all([
    db.collection("donate_money").get(),
    db.collection("donate_time").get(),
    db.collection("donate_courses").get(),
    db.collection("donate_equipment").get(),
  ])
    .then((querySnapshots) => {
      const res = {};

      const money = querySnapshots[0];
      const time = querySnapshots[1];
      const courses = querySnapshots[2];
      const equipment = querySnapshots[3];

      res.money = money.docs
        .map((row) => ({
          ...row.data(),
          ...calcFields(row),
        }))
        .filter((row) => row.email && row.payment_successful);

      // TODO add undefined check
      res.time = time.docs
        .map((row) => ({
          ...row.data(),
          ...calcFields(row),
        }))
        .filter((row) => row.email);

      res.courses = courses.docs
        .map((row) => ({
          ...row.data(),
          ...calcFields(row),
        }))
        .filter((row) => row.email);

      res.equipment = equipment.docs
        .map((row) => ({
          ...row.data(),
          ...calcFields(row),
        }))
        .filter((row) => row.email);

      return res;
    })
    .catch((e) => {
      alert(e);
      return [];
    });

const getCommonColumns = (idPrefix, dispatch, currentUser) => [
  {
    Header: "Profile",
    columns: [
      {
        Header: "First name",
        accessor: "first_name",
      },
      {
        Header: "Surname",
        accessor: "surname",
      },
      {
        Header: "Phone number",
        accessor: "phone",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Institution/Organisations name",
        accessor: "organization",
      },
    ],
  },
  {
    Header: "Additional",
    columns: [
      {
        Header: "Personal message",
        accessor: "message",
        show: false,
      },
      {
        Header: "Publish donation",
        accessor: "publish",
        Filter: SelectColumnFilter({ dispatch }),
        filter: "equals",
        show: false,
      },
      {
        Header: "Logo",
        accessor: "logo_name",
        disableFilters: true,
        Cell: ({ row }) => {
          const [src, setSrc] = useState();
          if (row.values.logo_name) {
            storageRef
              .child(`donate_${idPrefix}/${row.values.rowId}/logo`)
              .getDownloadURL()
              .then((url) => {
                setSrc(url);
              })
              .catch((error) => {
                alert(`Error loading file. ${error.message}`);
              });
          }
          return (
            <>
              <Avatar
                url={src}
                size="64"
                onClick={() => {
                  if (row.values.logo_name) {
                    window.open(src, "_blank");
                  }
                }}
              />
            </>
          );
        },
        show: false,
      },
    ],
  },
  {
    Header: "Info",
    columns: [
      {
        Header: "User ID",
        accessor: "uid",
        show: false,
      },
      {
        Header: "Entry ID",
        accessor: "rowId",
        show: false,
      },
      {
        Header: "Submission date",
        accessor: "date",
        sortType: "datetime",
        show: false,
        Cell: ({ row, data }) => data[row.index].dateFormatted,
      },
      {
        Header: "Verification status",
        accessor: "status",
        Filter: SelectColumnFilter({ dispatch }),
        filter: "includes",
        show: false,
      },
    ],
  },
  {
    Header: "Actions",
    columns: [
      {
        Header: "Actions",
        accessor: "actions",
        disableFilters: true,
        Cell: ({ row }) => (
          <span className="is-pulled-right nowrap">
            {row.values.status === "awaiting" && (
              <>
                <button
                  id={`approved-${idPrefix}-${row.values.rowId}`}
                  type="button"
                  className="button is-primary m-r-sm"
                  onClick={() => {
                    dispatch({
                      type: "approve",
                      reviewerId: currentUser.uid,
                      rowId: row.values.rowId,
                    });
                  }}
                >
                  Approve
                </button>
                <button
                  id={`rejected-${idPrefix}-${row.values.rowId}`}
                  type="button"
                  className="button is-danger m-r-sm"
                  onClick={() => {
                    dispatch({
                      type: "reject",
                      reviewerId: currentUser.uid,
                      rowId: row.values.rowId,
                    });
                  }}
                >
                  Reject
                </button>
              </>
            )}
          </span>
        ),
      },
    ],
  },
];

const Content = ({ currentUser }) => {
  const [moneyData, setMoneyData] = useState([]);
  const [timeData, setTimeData] = useState([]);
  const [coursesData, setCoursesData] = useState([]);
  const [equipmentData, setEquipmentData] = useState([]);
  const [donationsTotal, setDonationsTotal] = useState(0);
  const [moneyFilters, setMoneyFilters] = useState({});
  const [timeFilters, setTimeFilters] = useState({});
  const [coursesFilters, setCoursesFilters] = useState({});
  const [equipmentFilters, setEquipmentFilters] = useState({});
  const [moneyAwaiting, setMoneyAwaiting] = useState(0);
  const [timeAwaiting, setTimeAwaiting] = useState(0);
  const [coursesAwaiting, setCoursesAwaiting] = useState(0);
  const [equipmentAwaiting, setEquipmentAwaiting] = useState(0);

  const moneyMemoizedReducer = useCallback(
    createReducer(
      "money",
      moneyFilters,
      setMoneyFilters,
      moneyData,
      setMoneyData,
    ),
    [moneyFilters, moneyData],
  );
  const [moneyState, moneyDispatch] = useReducer(moneyMemoizedReducer, []);

  const timeMemoizedReducer = useCallback(
    createReducer("time", timeFilters, setTimeFilters, timeData, setTimeData),
    [timeFilters, timeData],
  );
  const [timeState, timeDispatch] = useReducer(timeMemoizedReducer, []);

  const coursesMemoizedReducer = useCallback(
    createReducer(
      "courses",
      coursesFilters,
      setCoursesFilters,
      coursesData,
      setCoursesData,
    ),
    [coursesFilters, coursesData],
  );
  const [coursesState, coursesDispatch] = useReducer(
    coursesMemoizedReducer,
    [],
  );

  const equipmentMemoizedReducer = useCallback(
    createReducer(
      "equipment",
      equipmentFilters,
      setEquipmentFilters,
      equipmentData,
      setEquipmentData,
    ),
    [equipmentFilters, equipmentData],
  );
  const [equipmentState, equipmentDispatch] = useReducer(
    equipmentMemoizedReducer,
    [],
  );

  const [moneyColumns, setMoneyColumns] = useState([
    {
      Header: "Donation",
      columns: [
        {
          Header: "Payment amount",
          accessor: "donation_amount",
          Filter: NumberRangeColumnFilter({ dispatch: moneyDispatch }),
          filter: "between",
        },
      ],
    },
    ...getCommonColumns("money", moneyDispatch, currentUser),
  ]);

  const [timeColumns, setTimeColumns] = useState([
    {
      Header: "Time & Skills",
      columns: [
        {
          Header: "Skills",
          accessor: "time-skills",
          // TODO add drop down filter
        },
        {
          Header: "Time available",
          accessor: "time-available",
          Filter: NumberRangeColumnFilter({ dispatch: timeDispatch }),
          filter: "between",
        },
        {
          Header: "Available per",
          accessor: "time-period",
          Filter: SelectColumnFilter({ dispatch: timeDispatch }),
          filter: "equals",
        },
        {
          Header: "Number of people",
          accessor: "time-people",
          Filter: NumberRangeColumnFilter({ dispatch: timeDispatch }),
          filter: "between",
          show: false,
        },
        {
          Header: "Basis",
          accessor: "time-basis",
          Filter: SelectColumnFilter({ dispatch: timeDispatch }),
          filter: "equals",
          show: false,
        },
        {
          Header: "From",
          accessor: "time-from",
          // sortType: "datetime",
          show: false,
        },
        {
          Header: "To",
          accessor: "time-to",
          // sortType: "datetime",
          show: false,
        },
      ],
    },
    ...getCommonColumns("time", timeDispatch, currentUser),
  ]);

  const [coursesColumns, setCoursesColumns] = useState([
    {
      Header: "Courses & Scholarships",
      columns: [
        {
          Header: "Description",
          accessor: "details",
        },
        {
          Header: "Syllabus",
          accessor: "file_name",
          disableFilters: true,
          Cell: ({ row }) => (
            <>
              {row.values.file_name && (
                <a
                  className="tabs-link nowrap"
                  target="_blank"
                  onClick={() => {
                    if (row.values.file_name) {
                      storageRef
                        .child(
                          `donate_courses/${row.values.rowId}/${row.values.file_name}`,
                        )
                        .getDownloadURL()
                        .then((url) => {
                          window.open(url, "_blank");
                        })
                        .catch((error) => {
                          alert(`Error loading file. ${error.message}`);
                        });
                    }
                  }}
                >
                  Download file
                </a>
              )}
            </>
          ),
        },
      ],
    },
    ...getCommonColumns("courses", coursesDispatch, currentUser),
  ]);

  const [equipmentColumns, setEquipmentColumns] = useState([
    {
      Header: "Tools & Equipment",
      columns: [
        {
          Header: "Description",
          accessor: "details",
        },
        {
          Header: "Syllabus",
          accessor: "file_name",
          disableFilters: true,
          Cell: ({ row }) => (
            <>
              {row.values.file_name && (
                <a
                  className="tabs-link nowrap"
                  target="_blank"
                  onClick={() => {
                    if (row.values.file_name) {
                      storageRef
                        .child(
                          `donate_equipment/${row.values.rowId}/${row.values.file_name}`,
                        )
                        .getDownloadURL()
                        .then((url) => {
                          window.open(url, "_blank");
                        })
                        .catch((error) => {
                          alert(`Error loading file. ${error.message}`);
                        });
                    }
                  }}
                >
                  Download file
                </a>
              )}
            </>
          ),
        },
      ],
    },
    ...getCommonColumns("equipment", equipmentDispatch, currentUser),
  ]);

  const hiddenColumns = [
    "message",
    "publish",
    "logo_name",
    "uid",
    "rowId",
    "date",
    "status",
  ];

  const [tabIndex, setTabIndex] = useState(0);

  const refreshData = (res) => {
    setMoneyData(res.money);
    setTimeData(res.time);
    setCoursesData(res.courses);
    setEquipmentData(res.equipment);
    setMoneyAwaiting(
      res.money.filter((row) => row.status === "awaiting").length,
    );
    setTimeAwaiting(res.time.filter((row) => row.status === "awaiting").length);
    setCoursesAwaiting(
      res.courses.filter((row) => row.status === "awaiting").length,
    );
    setEquipmentAwaiting(
      res.equipment.filter((row) => row.status === "awaiting").length,
    );
    setDonationsTotal(
      res.money.length +
        res.time.length +
        res.courses.length +
        res.equipment.length,
    );
  };

  // Have to use callback due to reducer firing twice because of component rerender https://stackoverflow.com/a/55056623
  const memoizedReducer = React.useCallback(
    createReducer(moneyData, refreshData),
    [moneyData],
  );
  const [state, dispatch] = useReducer(memoizedReducer, []);

  useEffect(() => {
    getData().then((res) => {
      refreshData(res);
    });
  }, []);

  useEffect(() => {
    setMoneyAwaiting(
      moneyData.filter((row) => row.status === "awaiting").length,
    );
    setTimeAwaiting(timeData.filter((row) => row.status === "awaiting").length);
    setCoursesAwaiting(
      coursesData.filter((row) => row.status === "awaiting").length,
    );
    setEquipmentAwaiting(
      equipmentData.filter((row) => row.status === "awaiting").length,
    );
  }, [moneyData, timeData, coursesData, equipmentData]);

  const showTab = (index) => {
    setTabIndex(index);
  };

  return (
    <div className="column is-12">
      <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
        Donations <span className="tag is-light m-l-xs">{donationsTotal}</span>
      </h2>
      <div className="columns is-desktop">
        <div className="column is-12">
          <div className="is-size-6 m-b-lg">
            <p>Quick filters:</p>
            <p>
              <a
                className="dotted"
                onClick={() => {
                  setMoneyFilters({ status: "awaiting" });
                  showTab(0);
                }}
              >
                Show unverified&nbsp;&nbsp;
                <span className="icon is-small">
                  <i className="far fa-money-bill-alt" />
                </span>
                &nbsp;&nbsp;money donations
              </a>
              <span className="tag is-light m-l-sm">{moneyAwaiting}</span>
            </p>
            <p>
              <a
                className="dotted"
                onClick={() => {
                  setTimeFilters({ status: "awaiting" });
                  showTab(1);
                }}
              >
                Show unverified&nbsp;&nbsp;
                <span className="icon is-small">
                  <i className="fas fa-hourglass-half" />
                </span>
                &nbsp;&nbsp;time donations
              </a>
              <span className="tag is-light m-l-sm">{timeAwaiting}</span>
            </p>
            <p>
              <a
                className="dotted"
                onClick={() => {
                  setCoursesFilters({ status: "awaiting" });
                  showTab(2);
                }}
              >
                Show unverified&nbsp;&nbsp;
                <span className="icon is-small">
                  <i className="fas fa-graduation-cap" />
                </span>
                &nbsp;&nbsp;courses donations
              </a>
              <span className="tag is-light m-l-sm">{coursesAwaiting}</span>
            </p>
            <p>
              <a
                className="dotted"
                onClick={() => {
                  setEquipmentFilters({ status: "awaiting" });
                  showTab(3);
                }}
              >
                Show unverified&nbsp;&nbsp;
                <span className="icon is-small">
                  <i className="fas fa-drafting-compass" />
                </span>
                &nbsp;&nbsp;equipment donations
              </a>
              <span className="tag is-light m-l-sm">{equipmentAwaiting}</span>
            </p>
            {/* <p>
          <a className="dotted">Show archived users</a>
          <span className="tag is-light m-l-sm">0</span>
        </p> */}
          </div>
          <Tabs
            className="tabs is-centered is-boxed is-toggle is-fullwidth"
            selectedTabClassName="is-active"
            selectedIndex={tabIndex}
            onSelect={(index) => {
              setTabIndex(index);
            }}
            forceRenderTabPanel
          >
            <TabList className="m-b-lg">
              <Tab>
                <a>
                  <span className="icon is-small">
                    <i className="far fa-money-bill-alt" />
                  </span>
                  <span>Financial</span>
                  <span className="tag is-light m-l-sm">
                    {moneyData.length}
                  </span>
                </a>
              </Tab>
              <Tab>
                <a>
                  <span className="icon is-small">
                    <i className="fas fa-hourglass-half" />
                  </span>
                  <span>Time & Skills</span>
                  <span className="tag is-light m-l-sm">{timeData.length}</span>
                </a>
              </Tab>
              <Tab>
                <a>
                  <span className="icon is-small">
                    <i className="fas fa-graduation-cap" />
                  </span>
                  <span>Courses & Scholarships</span>
                  <span className="tag is-light m-l-sm">
                    {coursesData.length}
                  </span>
                </a>
              </Tab>
              <Tab>
                <a>
                  <span className="icon is-small">
                    <i className="fas fa-drafting-compass" />
                  </span>
                  <span>Tools & Equipment</span>
                  <span className="tag is-light m-l-sm">
                    {equipmentData.length}
                  </span>
                </a>
              </Tab>
            </TabList>
            <TabPanel style={{ display: tabIndex === 0 ? "block" : "none" }}>
              <DonationsTable
                title="Financial"
                columns={moneyColumns}
                data={moneyData}
                filters={moneyFilters}
                setFilters={setMoneyFilters}
                dispatch={moneyDispatch}
                hiddenColumnsInit={hiddenColumns}
              />
            </TabPanel>
            <TabPanel style={{ display: tabIndex === 1 ? "block" : "none" }}>
              <DonationsTable
                title="Time & Skills"
                columns={timeColumns}
                data={timeData}
                filters={timeFilters}
                setFilters={setTimeFilters}
                dispatch={timeDispatch}
                hiddenColumnsInit={hiddenColumns}
              />
            </TabPanel>
            <TabPanel style={{ display: tabIndex === 2 ? "block" : "none" }}>
              <DonationsTable
                title="Courses & Scholarships"
                columns={coursesColumns}
                data={coursesData}
                filters={coursesFilters}
                setFilters={setCoursesFilters}
                dispatch={coursesDispatch}
                hiddenColumnsInit={hiddenColumns}
              />
            </TabPanel>
            <TabPanel style={{ display: tabIndex === 3 ? "block" : "none" }}>
              <DonationsTable
                title="Equipment"
                columns={equipmentColumns}
                data={equipmentData}
                filters={equipmentFilters}
                setFilters={setEquipmentFilters}
                dispatch={equipmentDispatch}
                hiddenColumnsInit={hiddenColumns}
              />
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

const UserList = () => {
  return <PlainPage Content={Content} isPrivate isAdminOnly isFluid />;
};

export default UserList;
