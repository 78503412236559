import React, { Fragment, useEffect, useState } from "react";
import { useTable, useSortBy, useFilters } from "react-table";
import { useDropdown, isNumeric } from "../utils/utils";
import { db } from "./firebase/firestore";

const DefaultColumnFilter = ({ dispatch }) => ({
  column: { id, filterValue, preFilteredRows },
}) => {
  const count = preFilteredRows.length;

  return (
    <input
      key={`${id}-filter`}
      className="input"
      value={filterValue || ""}
      onChange={(e) => {
        dispatch({
          type: "addFilter",
          id,
          value: e.target.value || "", // TODO delete the filter when empty value ""
        });
      }}
      placeholder={`Search ${count} records...`}
    />
  );
};

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
const NumberRangeColumnFilter = ({ dispatch }) => ({
  column: { filterValue = [], preFilteredRows, id },
}) => {
  const [min, max] = React.useMemo(() => {
    const intRows = preFilteredRows
      .map((row) => parseInt(row.values[id]))
      .filter((elem) => !Number.isNaN(elem));
    let min = intRows.length ? intRows[0] : 0;
    let max = intRows.length ? intRows[0] : 0;

    intRows.forEach((row) => {
      min = Math.min(row, min);
      max = Math.max(row, max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "baseline",
      }}
    >
      <input
        className="input"
        value={filterValue[0]}
        type="number"
        onChange={(e) => {
          const val = isNumeric(e.target.value)
            ? parseInt(e.target.value)
            : undefined;
          dispatch({
            type: "addFilterMin",
            id,
            value: val,
          });
        }}
        placeholder={`Min (${min})`}
        style={{
          width: "100px",
          marginRight: "0.5rem",
        }}
      />
      to
      <input
        className="input"
        value={filterValue[1]}
        type="number"
        onChange={(e) => {
          const val = isNumeric(e.target.value)
            ? parseInt(e.target.value)
            : undefined;
          dispatch({
            type: "addFilterMax",
            id,
            value: val,
          });
        }}
        placeholder={`Max (${max})`}
        style={{
          width: "100px",
          marginLeft: "0.5rem",
        }}
      />
    </div>
  );
};

// This is a custom filter UI for selecting
// a unique option from a list
const SelectColumnFilter = ({ dispatch }) => ({
  column: { filterValue, preFilteredRows, id },
}) => {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      if (row.values[id] !== "" && row.values[id] !== undefined) {
        options.add(row.values[id]);
      }
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <div className="select">
      <select
        value={filterValue}
        onChange={(e) => {
          dispatch({
            type: "addFilter",
            id,
            value: e.target.value || undefined, // TODO delete the filter when empty value ""
          });
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

const review = (status, idPrefix, reviewerId, rowId, data, setData) => {
  const button = document.getElementById(`${status}-${idPrefix}-${rowId}`);
  button.classList.add("is-loading");
  const ref = db.collection(`donate_${idPrefix}`).doc(rowId);
  ref
    .get()
    .then((doc) => {
      if (doc.exists) {
        ref.update({ status, reviewer_uid: reviewerId });
        const row = data[data.findIndex((u) => u.rowId === rowId)];
        row.status = status;
        row.reviewer_uid = reviewerId;
        setData([...data]);
      }
    })
    .catch((e) => alert(e))
    .finally(() => {
      button.classList.remove("is-loading");
    });
};

const createReducer = (idPrefix, filters, setFilters, data, setData) => (
  state,
  action,
) => {
  const removeProp = (prop) => {
    delete filters[prop];
    setFilters({
      ...filters,
    });
  };

  const minVal = filters[action.id] ? filters[action.id][0] : undefined;
  const maxVal = filters[action.id] ? filters[action.id][1] : undefined;
  switch (action.type) {
    case "addFilter":
      if (action.value !== undefined) {
        setFilters({ ...filters, [action.id]: action.value });
      } else {
        removeProp(action.id);
      }
      return [];
    case "addFilterMin":
      if (action.value !== undefined || maxVal !== undefined) {
        setFilters({
          ...filters,
          [action.id]: [action.value, maxVal],
        });
      } else {
        removeProp(action.id);
      }
      return [];
    case "addFilterMax":
      if (action.value !== undefined || minVal !== undefined) {
        setFilters({
          ...filters,
          [action.id]: [minVal, action.value],
        });
      } else {
        removeProp(action.id);
      }
      return [];
    case "approve":
      review(
        "approved",
        idPrefix,
        action.reviewerId,
        action.rowId,
        data,
        setData,
      );
      return [];
    case "reject":
      review(
        "rejected",
        idPrefix,
        action.reviewerId,
        action.rowId,
        data,
        setData,
      );
      return [];
    default:
      throw new Error("Unknown action.type");
  }
};

const Table = ({
  title,
  columns,
  data,
  usersAwaiting,
  usersRejected,
  filters,
  setFilters,
  dispatch,
  hiddenColumnsInit,
}) => {
  const columnsMemo = React.useMemo(() => columns, [columns]);
  const dataMemo = React.useMemo(() => data, [data]);

  const filterTypes = React.useMemo(
    () => ({
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    [],
  );

  const filtersMemo = React.useMemo(() => filters, [filters]);

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter({ dispatch }),
    }),
    [dispatch],
  );

  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    setHiddenColumns: updateHiddenColumns,
  } = useTable(
    {
      columns: columnsMemo,
      data: dataMemo,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      state: { filters: filtersMemo },
      initialState: { sortBy: [{ id: "date", desc: true }] },
    },
    useFilters,
    useSortBy,
  );

  const [hiddenColumns, setHiddenColumns] = useState(hiddenColumnsInit);

  useEffect(() => {
    updateHiddenColumns(hiddenColumns);
  }, [hiddenColumns, updateHiddenColumns]);

  const toggleColumn = (colId) => {
    if (hiddenColumns.includes(colId)) {
      setHiddenColumns(hiddenColumns.filter((col) => col !== colId));
    } else {
      setHiddenColumns([...hiddenColumns, colId]);
    }
  };

  return (
    <Fragment>
      <div
        className="panel-heading has-background-grey-lighter"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <span style={{ display: "flex" }}>
          <span className="">{title}</span>
          <span className="tag is-light m-l-sm">{rows.length}</span>
        </span>
        <span>
          <div className="dropdown is-hoverable is-right">
            <div className="dropdown-trigger">
              <button
                className="button"
                aria-haspopup="true"
                aria-controls="dropdown-menu"
                type="button"
              >
                <span>Show/hide columns</span>
                <span className="icon is-small">
                  <i
                    className="fas fa-angle-down has-text-grey-light"
                    aria-hidden="true"
                  />
                </span>
              </button>
            </div>
            <div className="dropdown-menu" id="dropdown-menu" role="menu">
              <div className="dropdown-content columns is-desktop m-t-none m-r-none">
                {columns.map((colGr) => (
                  <div key={colGr.Header}>
                    <div className="dropdown-item has-text-grey has-text-centered">
                      {colGr.Header}
                    </div>
                    {colGr.columns.map((col) => (
                      <label
                        className="dropdown-item checkbox"
                        key={col.accessor}
                      >
                        <input
                          type="checkbox"
                          defaultChecked={!hiddenColumns.includes(col.accessor)}
                          className="m-r-sm"
                          onClick={() => {
                            toggleColumn(col.accessor);
                          }}
                        />
                        {col.Header}
                      </label>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <button
            type="button"
            className="button m-l-md"
            onClick={() => {
              setFilters({});
            }}
          >
            Reset filters
          </button>
        </span>
      </div>
      <div className="table-container p-b-xl">
        <table
          {...getTableProps({
            className: "table is-hoverable is-fullwidth is-bordered",
          })}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps({
                  className: "has-background-white-ter",
                })}
              >
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps({
                      className: column.depth === 0 && "has-text-centered",
                      colSpan:
                        column.depth === 0
                          ? column.columns.filter((col) => col.isVisible).length
                          : 1, // fix for wrong colSpan when column is hidden. remove when https://github.com/tannerlinsley/react-table/issues/1446 is resolved second time
                    })}
                  >
                    <span
                      {...column.getSortByToggleProps({
                        title:
                          "Click to sort, hold Shift and click to multi-sort",
                        style: {
                          whiteSpace: "nowrap",
                        },
                      })}
                    >
                      {column.render("Header")}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <i className="fas fa-arrow-down m-l-sm" />
                        ) : (
                          // <i className="fas fa-sort-amount-down m-l-sm" />
                          <i className="fas fa-arrow-up m-l-sm" />
                          // <i className="fas fa-sort-amount-up-alt m-l-sm" />
                        )
                      ) : (
                        ""
                      )}
                      {/* {column.isSorted ? (column.isSortedDesc ? " ▼" : " ▲") : ""} */}
                      {/* {column.isSorted ? (column.isSortedDesc ? " ↓" : " ↑") : ""} */}
                    </span>
                    <div className={column.canFilter ? "m-t-xs m-b-xs" : ""}>
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {rows.map(
              (row, i) =>
                prepareRow(row) || (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                ),
            )}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

Table.propTypes = {};

export {
  Table as DonationsTable,
  NumberRangeColumnFilter,
  SelectColumnFilter,
  createReducer,
};
